/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

import {createApp} from 'vue';
import {createI18n} from 'vue-i18n';
import VueClipboard from 'vue3-clipboard';
/**
 * Using with i18next
 */
import i18next from 'i18next';
import i18nextLanguageBundle
    from '@kirschbaum-development/laravel-translations-loader?namespace=translation!@kirschbaum-development/laravel-translations-loader';

// Create a Vue app instance (assuming you have a root component)
const app = createApp(/* your root component */);

const i18n = createI18n({
    locale: 'en', // Set default locale
    debug: false,
    messages: {
        // Your translation messages here
    }
});

app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})
app.use(i18n);


const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default))

i18next.init({
    lng: window.Locale,
    debug: true,
    resources: i18nextLanguageBundle
}, function (err, t) {
    //console.log(i18next.t('auth.failed'));
});

app.mount('#app');


$(document).ready(function () {

    /*
     * Show loading icon on submit buttons after form submission
    */
    $('form:not(#app form, #logout-form)').each(function () {
        var submitButton = $(this).find('button[type="submit"]:not(.btn-danger)');
        submitButton.click(function (event) {
            event.preventDefault();
            var spinner = $('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');
            submitButton.append(spinner);
            submitButton.prop('disabled', true);
            this.form.submit();
        });
    });

    // Attach a click event listener to close buttons with data-dismiss="toast"
    $('[data-dismiss="toast"]').on('click', function () {
        // Find the parent toast element and hide it
        const toast = $(this).closest('#toastsContainerTopRight');
        if (toast.length) {
            toast.css('display', 'none');
        }
    });

    $('.toast').toast('show');


    var ssnInput = $('.format-ssn');
    var ssnInputReal = $('.real-ssn');
    var originalSSN = ssnInput.val();


    // Attach an event listener for input changes
    ssnInput.on('input change', function () {
        originalSSN = $(this).val();
        ssnInputReal.val(originalSSN.replace(/[^0-9]/g, ''));
        $(this).val(formatSSN(originalSSN));
    });

    // Attach an event listener for focusout
    ssnInput.on('focusout', function () {
        // Mask the SSN
        var maskedSSN = maskSSN($(this).val());
        $(this).val(maskedSSN);
    });

    // Attach an event listener for focusin
    ssnInput.on('focusin', function () {
        // Restore the original unmasked SSN
        $(this).val(formatSSN(ssnInputReal.val()));
    });

    // Helper function to format the SSN
    function formatSSN(value) {

        if(value) {
            var formattedSSN = '';
            value = value.replace(/[^0-9]/g, '');
            for (var i = 0; i < value.length; i++) {
                if (i === 3 || i === 5) {
                    formattedSSN += '-';
                }
                formattedSSN += value[i];
            }
            return formattedSSN;
        }
        return '';
    }

    function maskSSN(value) {
        var maskedSSN = value.replace(/\d/g, '*');
        return maskedSSN;
    }

    ssnInput.val(maskSSN(formatSSN(ssnInput.val())));

    // zip input box formatting
    var zipInput = $('.format-zip');

    // Attach an event listener for input changes
    zipInput.on('input', function () {
        // Remove any existing dashes and non-numeric characters
        var sanitizedValue = $(this).val().replace(/[^0-9]/g, '');

        // Format the SSN with dashes
        var formattedSSN = '';

        for (var i = 0; i < sanitizedValue.length; i++) {
            // Add a dash after the fifth digit
            if (i === 5) {
                formattedSSN += '-';
            }

            // Append the current digit
            formattedSSN += sanitizedValue[i];
        }

        // Update the input field with the formatted SSN
        $(this).val(formattedSSN);
    });

    // dollar input box formatting
    var dollarInput = $('.format-dollars');

    dollarInput.on('input', function () {

        let money = $(this).val().replace(/[^0-9]/g, '');

        if (money !== '') {
            money = parseFloat(money).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        }

        $(this).val(money);
    });

    // phone input box formatting
    var phoneInput = $('.format-phone');

    // Attach an event listener for input changes
    phoneInput.on('input', function () {
        // Remove any existing dashes and non-numeric characters
        var sanitizedValue = $(this).val().replace(/[^0-9]/g, '');

        // Format the SSN with dashes
        var formattedSSN = '';

        for (var i = 0; i < sanitizedValue.length; i++) {
            // Add a dash after the fifth digit
            if (i === 3 || i === 6) {
                formattedSSN += '-';
            }

            // Append the current digit
            formattedSSN += sanitizedValue[i];
        }

        // Update the input field with the formatted SSN
        $(this).val(formattedSSN);
    });

});

// change nav arrow icon on collapse
document.addEventListener('DOMContentLoaded', function() {
    const menuItems = document.querySelectorAll('.nav-item > .nav-link[data-bs-toggle="collapse"]');

    menuItems.forEach(menuItem => {
        const chevron = menuItem.querySelector('.nav-arrow');
        const submenuId = menuItem.getAttribute('data-bs-target');
        const submenu = document.querySelector(submenuId);

        // Only add listeners if both chevron and submenu exist
        if (chevron && submenu) {
            menuItem.addEventListener('click', function(e) {
                e.preventDefault();
                toggleChevron(chevron);
            });

            submenu.addEventListener('show.bs.collapse', function() {
                rotateChevron(chevron, 90);
            });

            submenu.addEventListener('hide.bs.collapse', function() {
                rotateChevron(chevron, 0);
            });
        }
    });

    function toggleChevron(chevron) {
        if (!chevron) return;
        const currentRotation = getRotation(chevron);
        rotateChevron(chevron, currentRotation === 90 ? 0 : 90);
    }

    function rotateChevron(chevron, degrees) {
        if (!chevron) return;
        chevron.style.transform = `translateY(-50%) rotate(${degrees}deg)`;
    }

    function getRotation(element) {
        if (!element) return 0;
        const transform = window.getComputedStyle(element).getPropertyValue('transform');
        if (transform === 'none') return 0;
        const values = transform.split('(')[1].split(')')[0].split(',');
        const a = values[0];
        const b = values[1];
        return Math.round(Math.atan2(b, a) * (180/Math.PI));
    }
});

document.addEventListener('DOMContentLoaded', function() {
    const sidebar = document.getElementById('sidebar');

    // Close sidebar when clicking outside of it (mobile only)
    document.addEventListener('click', function(event) {
        if (window.innerWidth <= 992 &&
            sidebar.classList.contains('show') &&
            !sidebar.contains(event.target) &&
            !event.target.closest('[data-bs-toggle="collapse"]')) {

            bootstrap.Collapse.getInstance(sidebar).hide();
        }
    });

    // Handle resize events
    function handleResize() {
        if (window.innerWidth > 992) {
            sidebar.classList.remove('collapse');
            sidebar.classList.add('show');
            sidebar.style.left = '';
        } else {
            if (!sidebar.classList.contains('collapse')) {
                sidebar.classList.add('collapse');
            }
        }
    }

    // Initial check on load
    handleResize();

    // Listen for window resize
    window.addEventListener('resize', handleResize);
});
