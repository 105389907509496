<template>
    <div>
        <form @submit.prevent="savePrioritySetting">
            <div class="card-body">
                <!-- Success Banner -->
                <div v-if="updateSuccess" class="alert alert-success mt-3">
                    Priority Set {{ isEditing ? 'updated' : 'created' }} successfully.
                </div>

                <!-- Priority Setting Form -->
                <div class="row mb-3">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="name" required>Priority Set Name</label>
                            <input
                                type="text"
                                id="name"
                                class="form-control"
                                v-model="PrioritySetting.name"
                                required
                            />
                        </div>
                    </div>
                </div>

                <!-- Attribute Selection -->
                <label required>Attributes</label>

                <div v-for="(attribute, index) in selectedAttributes" :key="index">
                    <div class="row mb-3">
                        <div class="col-md-5">
                            <div class="form-group">
                                <v-select
                                    v-model="attribute.selected"
                                    :options="getAvailableAttributes(index)"
                                    label="name"
                                    :reduce="attr => attr.attribute"
                                    placeholder="Select an attribute or type to search"
                                    :clearable="false"
                                    required
                                ></v-select>
                            </div>
                        </div>

                        <!-- Sort Direction Selection -->
                        <div class="col-md-2">
                            <select
                                class="form-select"
                                v-model="attribute.is_ascending"
                                required
                            >
                                <option :value="true">Ascending</option>
                                <option :value="false">Descending</option>
                            </select>
                        </div>

                        <!-- Remove Attribute Button -->
                        <div class="col-md-1">
                            <button
                                type="button"
                                class="btn btn-danger btn-block"
                                @click="removeAttribute(index)"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Add Attribute Button -->
                <button
                    type="button"
                    class="btn btn-sm btn-secondary mb-3"
                    @click="addAttribute"
                    :disabled="selectedAttributes.length >= 25"
                >
                    Add Attribute
                </button>

                <!-- Attribute Limit Warning -->
                <div v-if="selectedAttributes.length >= 25" class="alert alert-warning mt-3">
                    You have reached the maximum limit of 25 attributes. Contact your administrator if you need more.
                </div>
            </div>

            <!-- Form Footer -->
            <div class="card-footer">
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-primary">
                            {{ isEditing ? 'Update' : 'Create' }} Priority Setting
                        </button>
                    </div>
                    <div class="col text-end" v-if="isEditing">
                        <button type="button" class="btn btn-danger" @click="showDeleteWarning">
                            <i class="fa-solid fa-trash fa-fw"></i> Delete Priority Setting
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
    components: {
        vSelect
    },
    props: {
        prioritySettingData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            PrioritySetting: {
                name: this.prioritySettingData.name || '',
            },
            selectedAttributes: this.initializeSelectedAttributes(),
            availableAttributes: [],
            updateSuccess: false,
        };
    },
    computed: {
        isEditing() {
            return !!this.prioritySettingData.slug;
        }
    },
    mounted() {
        this.fetchAttributes();
        this.ensureAtLeastOneAttribute();
    },
    methods: {
        initializeSelectedAttributes() {
            if (this.prioritySettingData.attributes && this.prioritySettingData.attributes.length > 0) {
                return this.prioritySettingData.attributes.slice(0, 25).map(attr => ({
                    selected: attr.attribute,
                    slug: attr.slug,
                    is_ascending: Boolean(attr.is_ascending) // Convert to boolean
                }));
            }
            return [{ selected: null, slug: null, is_ascending: true }];
        },
        fetchAttributes() {
            axios.get('/ps/get-attributes-for-user')
                .then(response => {
                    this.availableAttributes = response.data;
                })
                .catch(error => {
                    console.error('Error fetching attributes:', error);
                });
        },
        addAttribute() {
            if (this.selectedAttributes.length < 25) {
                this.selectedAttributes.push({ selected: null, slug: null, is_ascending: true });
            }
        },
        removeAttribute(index) {
            this.selectedAttributes.splice(index, 1);
            this.ensureAtLeastOneAttribute();
        },
        ensureAtLeastOneAttribute() {
            if (this.selectedAttributes.length === 0) {
                this.addAttribute();
            }
        },
        getAvailableAttributes(currentIndex) {
            return this.availableAttributes.filter(attr =>
                !this.selectedAttributes.some((selected, index) =>
                    index !== currentIndex && selected.selected === attr.attribute
                )
            );
        },
        savePrioritySetting() {
            const payload = {
                priority_setting: {
                    name: this.PrioritySetting.name,
                    attributes: this.selectedAttributes
                        .filter(attr => attr.selected)
                        .map(attr => ({
                            attribute: attr.selected,
                            slug: attr.slug,
                            is_ascending: attr.is_ascending
                        }))
                }
            };

            const url = this.isEditing
                ? `/ps/priority-set/${this.prioritySettingData.slug}`
                : '/ps/priority-set';

            const method = this.isEditing ? 'put' : 'post';

            axios[method](url, payload)
                .then(response => {
                    this.updateSuccess = true;
                    setTimeout(() => {
                        window.location.href = '/ps/priority-sets';
                    }, 1000);
                })
                .catch(error => {
                    console.error('Error saving Priority Set:', error);
                });
        },
        showDeleteWarning() {
            const confirmation = window.confirm('Are you sure you want to delete this Priority Set?');
            if (confirmation) {
                this.deletePrioritySetting();
            }
        },
        deletePrioritySetting() {
            const slug = this.prioritySettingData.slug;
            if (slug) {
                axios.delete(`/ps/priority-set/delete/${slug}`)
                    .then(response => {
                        this.updateSuccess = true;
                    })
                    .catch(error => {
                        console.error('Error deleting Priority Set:', error);
                    });
                setTimeout(() => {
                    window.location.href = '/ps/priority-sets';
                }, 1000);
            }
        }
    }
};
</script>
