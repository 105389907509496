<template>
    <form @submit.prevent="submit">

        <div class="card-body">

            <div v-if="success" class="alert alert-success" role="alert">
                <span>
                    {{ message }}
                </span>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="firstname" required>{{ translations['base_application::forms.create_user_label_name_first'] }}</label>
                        <input type="text" class="form-control" id="firstname" name="firstname" v-model="fields.firstname" required>
                        <div v-if="errors && errors.firstname" class="text-danger">{{ errors.firstname[0] }}</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                      <label for="middlename">{{ translations['base_application::forms.create_user_label_name_middle'] }}</label>
                      <input type="text" class="form-control" id="middlename" name="middlename" v-model="fields.middlename">
                      <div v-if="errors && errors.middlename" class="text-danger">{{ errors.middlename[0] }}</div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                      <label for="lastname" required>{{ translations['base_application::forms.create_user_label_name_last'] }}</label>
                      <input type="text" class="form-control" id="lastname" name="lastname" v-model="fields.lastname" required>
                      <div v-if="errors && errors.lastname" class="text-danger">{{ errors.lastname[0] }}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="email" required>{{ translations['base_application::forms.create_user_label_email'] }}</label>
                <input type="email" class="form-control" id="email" name="email" v-model="fields.email" required>
                <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
            </div>
            <div class="form-group">
                <label for="role" required>{{ translations['base_application::forms.create_user_label_role'] }}</label>
                <select name="role" class="form-select" id="role" v-model="fields.role" @change="loadBranches()" required>
                    <option value="">{{ translations['base_application::forms.create_user_select_role'] }}</option>
                    <option v-for="(role, key) in roles" v-bind:value="key">{{ role }}</option>
                </select>
                <div v-if="errors && errors.role" class="text-danger">{{ errors.role[0] }}</div>
            </div>
            <div id="company" class="form-group" v-if="can_toggle_company && roles_requiring_company.includes(fields.role)">
                <label for="company">{{ translations['base_application::forms.create_user_label_company'] }}</label>
                <select name="company_slug" class="form-select" id="company_slug" v-model="fields.company_slug" @change="loadBranches()">
                    <option value="">{{ translations['base_application::forms.create_user_select_company'] }}</option>
                    <option
                        v-for="company in companies"
                        v-bind:value="company.slug">
                        {{ company.name }}
                    </option>
                </select>
                <div v-if="errors && errors.company_slug" class="text-danger">{{ errors.company_slug[0] }}</div>
            </div>
            <div id="branch" class="form-group" v-if="can_toggle_branch && roles_requiring_branch.includes(fields.role)">
                <label for="branch">{{ translations['base_application::forms.create_user_label_branch'] }}</label>
                <select name="branch_slug" class="form-select" id="branch_slug" v-model="fields.branch_slug">
                    <option value="">{{ translations['base_application::forms.create_user_select_branch'] }}</option>
                    <option v-for="branch in branches" v-bind:value="branch.slug">{{ branch.name }}</option>
                </select>
                <div v-if="errors && errors.branch_slug" class="text-danger">{{ errors.branch_slug[0] }}</div>
            </div>
            <div class="form-group" v-if="can_manage_tracking">
                <label for="tracking_id">{{ translations['base_application::forms.create_user_label_tracking_id'] }}</label>
                <input name="tracking_id" type="text" class="form-control" id="tracking_id" v-model="fields.tracking_id">
                <div v-if="errors && errors.tracking_id" class="text-danger">{{ errors.tracking_id[0] }}</div>
            </div>

        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-primary" :disabled="!loaded">
            <span v-if="!loaded" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{ translations['base_application::forms.button_submit'] }}
          </button>
        </div>
    </form>
</template>

<script>
    import i18next from "i18next";

    export default {
        data() {
            return {
                loaded: true,
                success: false,
                branches: {},
                errors: {},
                fields: {},
                message: '',
                urlParams: null
            }
        },
        props: [
            'companies',
            'roles',
            'roles_requiring_company',
            'roles_requiring_branch',
            'can_manage_tracking',
            'can_toggle_company',
            'can_toggle_branch',
            'translations',
        ],
        created() {
            if (!this.can_toggle_company) {
                // This is either a branch manager or company manager there is only one company
                this.fields.company_slug = this.companies[0].slug;
                console.log(this.fields.company_slug);
                if (!this.can_toggle_branch) {
                    // This is is branch manager
                    this.loadBranch();
                }
            }
            let params = new URLSearchParams(window.location.search);
            this.urlParams = { 'c': params.get('c'), 'b': params.get('b')};
            if (this.urlParams.c) {
                this.fields.company_slug = this.urlParams.c;
            }
        },
        methods: {
            submit() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = false;
                    this.errors = {};
                    axios.post('/store-user', this.fields).then(response => {
                        this.fields = {}; // Clear input fields.
                        this.loaded = true;
                        this.success = true;
                        this.message = response.data.msg;

                        window.location.href = '/manage-users';
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            },
            // Used to get branc for branch manager
            loadBranch: function () {
                axios.get('/get-branches-by-company/' + this.fields.company_slug)
                    .then(response => {
                        this.branches = response.data;
                        this.fields.branch_slug = this.branches[0].slug;
                    }).catch(error => {
                    // console.log(error);
                });
            },
            loadBranches: function () {
                if (this.can_toggle_branch && this.roles_requiring_branch.includes(this.fields.role)) {
                    axios.get('/get-branches-by-company/' + this.fields.company_slug)
                        .then(response => {
                            this.branches = response.data;
                            if (this.urlParams.c == this.fields.company_slug && this.urlParams.b) {
                                this.fields.branch_slug = this.urlParams.b;
                            }
                        }).catch(error => {
                        // console.log(error);
                    });
                }
            },
        }
    }
</script>
