<template>
    <div class="criteria-set-component">
        <label>Criteria Sets</label>
        <div class="mb-3">
            <div class="form-check">
                <input
                    type="radio"
                    id="useExistingCriteria"
                    value="existing"
                    v-model="criteriaSetType"
                    class="form-check-input"
                    @change="handleCriteriaSetTypeChange"
                />
                <label for="useExistingCriteria" class="form-check-label">Use existing criteria set</label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    id="createNewCriteria"
                    value="new"
                    v-model="criteriaSetType"
                    class="form-check-input"
                    @change="handleCriteriaSetTypeChange"
                />
                <label for="createNewCriteria" class="form-check-label">Create new criteria set</label>
            </div>
        </div>

        <div v-if="criteriaSetType === 'existing'">
            <div class="row">
                <div class="col-sm-9 col-xl-6">
                    <v-select
                        v-model="selectedCriteriaSet"
                        :options="criteriaSets"
                        label="name"
                        :reduce="set => set.slug"
                        @input="loadCriteriaSet"
                        placeholder="Select a Criteria Set"
                        :clearable="false"
                        class="mb-3"
                    ></v-select>
                </div>
            </div>
        </div>

        <div
            v-if="criteriaSetType === 'new' || (criteriaSetType === 'existing' && selectedCriteriaSet)"
            class="mb-2"
        >
            <label
                :for="criteriaSetType === 'new' ? 'newCriteriaSetName' : 'existingCriteriaSetName'"
                class="form-label"
                required
            >
                Criteria Set Name
            </label>
            <div class="row">
                <div class="col-sm-9 col-xl-6">
                    <input
                        :id="criteriaSetType === 'new' ? 'newCriteriaSetName' : 'existingCriteriaSetName'"
                        v-model="criteriaSetName"
                        class="form-control mb-2"
                        @input="checkForChanges"
                        placeholder="Criteria Set Name"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="criteriaSetType === 'new' || (criteriaSetType === 'existing' && selectedCriteriaSet)"
            class="mb-3"
        >
            <label required>Criteria</label>
            <div
                v-for="(criterion, index) in displayedCriteria"
                :key="criterion.id || index"
                class="mb-2"
            >
                <div
                    :class="{
                        border: criterion.to_be_deleted,
                        'border-danger': criterion.to_be_deleted,
                        'my-2': criterion.to_be_deleted,
                        'p-2': criterion.to_be_deleted,
                    }"
                >
                    <div v-if="criterion.to_be_deleted">
                        <strong>Marked for deletion</strong>
                    </div>
                    <div class="row">
                        <!-- Attribute Selection -->
                        <div class="col-md-4">
                            <v-select
                                v-model="criterion.attribute"
                                :options="attributes"
                                label="name"
                                :reduce="attribute => attribute"
                                placeholder="Select an option or type to search"
                                :clearable="false"
                                @input="onAttributeChange(criterion)"
                            ></v-select>
                        </div>
                        <!-- Rule Selection -->
                        <div class="col-md-3">
                            <select
                                v-model="criterion.operator"
                                class="form-select"
                                @change="checkForChanges"
                            >
                                <option value="">Select a rule</option>
                                <option
                                    v-for="op in getAvailableOperators(criterion.attribute)"
                                    :key="op.value"
                                    :value="op.value"
                                >
                                    {{ op.label }}
                                </option>
                            </select>
                        </div>
                        <!-- Value Input (conditionally rendered) -->
                        <div class="col-md-3" v-if="shouldShowValueInput(criterion.operator)">
                            <input
                                v-model="criterion.value"
                                class="form-control"
                                placeholder="Value"
                                @input="checkForChanges"
                            />
                        </div>
                        <!-- Remove Criterion Button -->
                        <div class="col-md-2">
                            <button
                                v-if="!criterion.to_be_deleted"
                                @click="removeCriterion(index)"
                                class="btn btn-danger"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                            <button
                                v-else
                                @click="cancelDeleteCriterion(index)"
                                class="btn btn-danger"
                            >
                                <i class="fas fa-undo-alt" style="font-size: 1.5rem; cursor: pointer;"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button @click="addCriterion" class="btn btn-secondary mt-2">
                    Add Additional Criteria
                </button>
            </div>
        </div>

        <div
            v-if="criteriaSetType === 'new' || (criteriaSetType === 'existing' && selectedCriteriaSet)"
            class="mt-3 mb-3"
        >
            <button
                @click="saveCriteriaSet"
                class="btn btn-primary"
                :disabled="!isChanged || !isValid"
            >
                Save Criteria Set
            </button>
        </div>

        <div v-if="updateSuccess" class="alert alert-success mt-3">
            Criteria Set saved successfully.
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import axios from 'axios';
import vSelect from 'vue-select';

export default {
    name: 'ProgramCriteriaSetComponent',
    components: {
        vSelect,
    },
    props: {
        criteriaSetType: {
            type: String,
            default: 'none',
        },
        selectedCriteriaSet: {
            type: String,
            default: '',
        },
        criteriaSets: {
            type: Array,
            default: () => [],
        },
        initialCriteriaSetSlug: {
            type: String,
            default: '',
        },
        selectedCompany: {
            type: [Object],
            required: true
        }
    },
    emits: [
        'update:criteriaSetSlug',
        'criteria-set-changed',
        'criteria-set-saved',
        'criteria-set-type-changed',
    ],
    setup(props, { emit }) {
        const criteriaSetType = ref('existing');
        const selectedCriteriaSet = ref(props.initialCriteriaSetSlug);
        const criteriaSetName = ref('');
        const existingCriteria = ref([]);
        const newCriteria = ref([]);
        const criteriaSets = ref([]);
        const attributes = ref([]);
        const allOperators = [
            { label: 'Equals', value: '==' },
            { label: 'Not Equals', value: '!=' },
            { label: 'Greater Than', value: '>' },
            { label: 'Less Than', value: '<' },
            { label: 'Greater Than or Equal To', value: '>=' },
            { label: 'Less Than or Equal To', value: '<=' },
            { label: 'IN', value: 'IN' },
            { label: 'NOT IN', value: 'NOT IN' },
            { label: 'Is Null', value: 'isNull' },
            { label: 'Is Not Null', value: 'isNotNull' },
        ];

        const originalCriteriaSet = ref(null);
        const isChanged = ref(false);
        const updateSuccess = ref(false);

        const displayedCriteria = computed(() => {
            return criteriaSetType.value === 'new' ? newCriteria.value : existingCriteria.value;
        });

        const shouldShowValueInput = (operator) => {
            return operator !== 'isNull' && operator !== 'isNotNull';
        };

        const isValid = computed(() => {
            return (
                criteriaSetName.value.trim() !== '' &&
                displayedCriteria.value.length > 0 &&
                displayedCriteria.value.every(
                    (criterion) =>
                        criterion.attribute &&
                        criterion.operator &&
                        (shouldShowValueInput(criterion.operator) ? criterion.value.trim() !== '' : true)
                )
            );
        });

        const checkForChanges = () => {
            if (!originalCriteriaSet.value) return;

            const currentState = {
                name: criteriaSetName.value,
                criteria: criteriaSetType.value === 'new' ? newCriteria.value : existingCriteria.value,
            };

            isChanged.value =
                JSON.stringify(currentState) !== JSON.stringify(originalCriteriaSet.value);
            emit('criteria-set-changed', isChanged.value);
        };

        watch([criteriaSetName, newCriteria, existingCriteria], checkForChanges, { deep: true });

        const loadCriteriaSets = async () => {
            try {
                const response = await axios.get('/ps/get-criteria-for-user');
                criteriaSets.value = response.data;
            } catch (error) {
                console.error('Error loading criteria sets:', error);
            }
        };

        const loadAttributes = async () => {
            try {
                const response = await axios.get('/ps/get-attributes-for-user');
                attributes.value = response.data;
            } catch (error) {
                console.error('Error loading attributes:', error);
            }
        };

        const loadCriteriaSet = async () => {
            if (
                selectedCriteriaSet.value &&
                (criteriaSetType.value === 'existing' || criteriaSetType.value === 'use')
            ) {
                const selectedSet = criteriaSets.value.find(
                    (set) => set.slug === selectedCriteriaSet.value
                );
                if (selectedSet) {
                    criteriaSetName.value = selectedSet.name;
                    existingCriteria.value = selectedSet.configs.map((config) => {
                        const matchingAttribute = attributes.value.find(
                            (attr) => attr.attribute === config.attribute
                        );
                        return {
                            id: config.id,
                            slug: config.slug,
                            attribute: matchingAttribute || { name: config.attribute },
                            operator: config.rule,
                            value: config.value,
                            to_be_deleted: false,
                        };
                    });
                    emit('update:criteriaSetSlug', selectedCriteriaSet.value);

                    originalCriteriaSet.value = {
                        name: criteriaSetName.value,
                        criteria: JSON.parse(JSON.stringify(existingCriteria.value)),
                    };
                    isChanged.value = false;
                    emit('criteria-set-changed', false);
                }
            } else {
                resetNewCriteriaSet();
            }
        };

        const resetNewCriteriaSet = () => {
            criteriaSetName.value = '';
            newCriteria.value = [
                { id: Date.now(), slug: null, attribute: null, operator: '', value: '' },
            ];
            originalCriteriaSet.value = {
                name: '',
                criteria: [
                    { id: Date.now(), slug: null, attribute: null, operator: '', value: '' },
                ],
            };
            isChanged.value = false;
            emit('criteria-set-changed', false);
        };

        const addCriterion = () => {
            const newCriterion = {
                id: Date.now(),
                slug: null,
                attribute: null,
                operator: '',
                value: '',
            };

            if (criteriaSetType.value === 'new') {
                newCriteria.value.push(newCriterion);
            } else {
                existingCriteria.value.push(newCriterion);
            }
            checkForChanges();
        };

        const removeCriterion = (index) => {
            const criterion = displayedCriteria.value[index];
            if (criterion.slug) {
                criterion.to_be_deleted = true;
            } else {
                displayedCriteria.value.splice(index, 1);
            }
            checkForChanges();
        };

        const cancelDeleteCriterion = (index) => {
            const criterion = displayedCriteria.value[index];
            if (criterion.slug && criterion.to_be_deleted) {
                criterion.to_be_deleted = false;
                checkForChanges();
            }
        };

        const saveCriteriaSet = async () => {
            try {
                const payload = {
                    name: criteriaSetName.value,
                    criteria_configs: displayedCriteria.value.map((criterion) => ({
                        id: criterion.id,
                        slug: criterion.slug,
                        attribute: criterion.attribute.name, // Send attribute name to API
                        rule: criterion.operator,
                        value: criterion.value,
                        to_be_deleted: criterion.to_be_deleted || false,
                    })),
                    company: props.selectedCompany
                };

                let response;
                let savedCriteriaSetSlug;

                if (criteriaSetType.value === 'new') {
                    response = await axios.post('/ps/criteria-set', payload);
                    savedCriteriaSetSlug = response.data.criteria_set_id;
                } else {
                    response = await axios.put(
                        `/ps/criteria-set/${selectedCriteriaSet.value}`,
                        payload
                    );
                    savedCriteriaSetSlug = response.data.criteria_set_id;
                }

                // Reload criteria sets and select the saved one
                await reloadCriteriaSets(savedCriteriaSetSlug);

                updateSuccess.value = true;
                emit('criteria-set-saved', savedCriteriaSetSlug);

                setTimeout(() => {
                    updateSuccess.value = false;
                }, 3000);
            } catch (error) {
                console.error('Error saving criteria set:', error);
                alert('Error saving criteria set. Please try again.');
            }
        };

        const reloadCriteriaSets = async (criteriaSetSlugToSelect) => {
            try {
                await loadCriteriaSets();
                criteriaSetType.value = 'existing';

                // Attempt to find the criteria set to select
                let setToSelect = criteriaSets.value.find(
                    (set) => set.slug === criteriaSetSlugToSelect
                );

                if (setToSelect) {
                    selectedCriteriaSet.value = setToSelect.slug;
                    criteriaSetName.value = setToSelect.name;

                    await loadCriteriaSet();
                } else {
                    console.error('Could not find the saved criteria set in the reloaded list');
                    // Fallback: select the first set in the list if available
                    if (criteriaSets.value.length > 0) {
                        selectedCriteriaSet.value = criteriaSets.value[0].slug;
                        criteriaSetName.value = criteriaSets.value[0].name;
                        await loadCriteriaSet();
                    }
                }

                isChanged.value = false;
                emit('update:criteriaSetSlug', selectedCriteriaSet.value);
            } catch (error) {
                console.error('Error reloading criteria sets:', error);
            }
        };

        const handleCriteriaSetTypeChange = () => {
            emit('criteria-set-type-changed', criteriaSetType.value === 'new');
            if (criteriaSetType.value === 'new') {
                resetNewCriteriaSet();
            } else {
                loadCriteriaSet();
            }
        };

        const getAvailableOperators = (attribute) => {
            if (!attribute || !attribute.type) {
                // Exclude numeric operators if data_type is not 'int' or 'decimal'
                return allOperators.filter(
                    (op) => !['>', '<', '>=', '<='].includes(op.value)
                );
            }
            if (attribute.type === 'int' || attribute.type === 'decimal') {
                // Include all operators for numeric types
                return allOperators;
            }
            // Exclude numeric operators for other data types
            return allOperators.filter((op) => !['>', '<', '>=', '<='].includes(op.value));
        };

        const onAttributeChange = (criterion) => {
            // Reset the operator if it's no longer valid
            const availableOps = getAvailableOperators(criterion.attribute);
            if (!availableOps.find((op) => op.value === criterion.operator)) {
                criterion.operator = '';
            }
            checkForChanges();
        };

        watch(
            () => props.initialCriteriaSetSlug,
            (newValue) => {
                if (newValue) {
                    criteriaSetType.value = 'existing';
                    selectedCriteriaSet.value = newValue;
                    loadCriteriaSet();
                }
            }
        );

        watch(selectedCriteriaSet, () => {
            loadCriteriaSet();
        });

        watch(criteriaSetType, (newValue) => {
            if (newValue === 'new') {
                resetNewCriteriaSet();
            } else {
                selectedCriteriaSet.value = null;
                criteriaSetName.value = '';
                existingCriteria.value = [];
                originalCriteriaSet.value = null;
                isChanged.value = false;
                emit('criteria-set-changed', false);
            }
        });

        onMounted(async () => {
            await loadCriteriaSets();
            await loadAttributes();
            if (props.initialCriteriaSetSlug) {
                loadCriteriaSet();
            } else {
                selectedCriteriaSet.value = null;
                criteriaSetName.value = '';
                existingCriteria.value = [];
            }
        });

        return {
            criteriaSetType,
            selectedCriteriaSet,
            criteriaSetName,
            displayedCriteria,
            criteriaSets,
            attributes,
            isChanged,
            isValid,
            updateSuccess,
            loadCriteriaSet,
            addCriterion,
            removeCriterion,
            cancelDeleteCriterion,
            saveCriteriaSet,
            checkForChanges,
            handleCriteriaSetTypeChange,
            reloadCriteriaSets,
            getAvailableOperators,
            onAttributeChange,
            shouldShowValueInput,
        };
    },
};
</script>
