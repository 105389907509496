<template>
    <div class="swagger" id="swagger"></div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import axios from 'axios';

export default {
    props: ['app-name'],
    data() {
        return {
            permissionMappings: {},
            'version': '',
        };
    },
    mounted() {
        this.loadPermissionMappings();
        this.loadCompanySettings();
        this.initializeSwaggerUI();
        this.checkPermissions();
    },
    methods: {
        loadSwagger(spec) {
            axios.get('/user/get-version')
                .then(response => {
                    spec.info.version = response.data.version;
                    const ui = SwaggerUI({
                        spec: spec,
                        dom_id: '#swagger',
                        tryItOutEnabled: true,
                        tagsSorter: "alpha",
                        onComplete: () => {
                            // Swagger UI has loaded
                            this.checkPermissions();
                        }
                    });
                })
                .catch(error => {
                    console.error('Failed to fetch version:', error);
                });
        },
        loadPermissionMappings() {
            try {
                this.permissionMappings = require('../swagger-configs/swagger-permissions.json'); // Adjust the path as necessary
            } catch (error) {
                console.warn('Permission mappings file not found. All operations will be shown.');
            }
        },
        loadCompanySettings() {
            try {
                this.companySettings = require('../swagger-configs/swagger-company-settings.json'); // Adjust the path as necessary
            } catch (error) {
                console.warn('Company settings file not found. All operations will be shown.');
            }
        },
        initializeSwaggerUI() {
            const spec = require('../swagger-configs/openapi.json');
            spec.info.title = this.appName;  // Update the title
            this.loadSwagger(spec);
        },
        checkPermissions() {
            axios.get('/user-data')
                .then(response => {
                    const userPermissions = response.data.permissions;
                    const companySettings = response.data.companySettings ?? [];
                    this.hideUnauthorizedSections(userPermissions);
                    // Super admins and resellers can always see entire api documentation
                    if (!userPermissions.includes('create Super Admin')) {
                        // show the create company button
                        this.hideFromCompanySettings(companySettings);
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch permissions:', error);
                });
        },
        hideUnauthorizedSections(userPermissions) {
            for (const [dataTag, requiredPermission] of Object.entries(this.permissionMappings)) {
                if (!userPermissions.includes(requiredPermission)) {
                    // Hide endpoints
                    const elements = document.querySelectorAll(`[data-tag="${dataTag}"]`);
                    elements.forEach(element => {
                        const section = element.closest('.opblock-tag-section');
                        if (section) {
                            section.style.display = 'none';
                        }
                    });

                    // Hide corresponding schemas
                    const schemaSection = document.querySelector('.swagger-ui .models');
                    if (schemaSection) {
                        // Convert tag to schema format (e.g., "Companies" -> "Prescreen: Companies")
                        const schemaName = `Prescreen${dataTag}`;
                        const modelContainer = document.querySelector(`[data-name="Prescreen${dataTag}"]`);
                        if (modelContainer) {
                            modelContainer.closest('.model-container').style.display = 'none';
                        }
                    }
                }
            }
        },
        hideFromCompanySettings(companySettings) {

            for (const [setting_key, companySetting] of Object.entries(this.companySettings)) {

                if (!companySettings.includes(setting_key)) {
                    const elements = document.querySelectorAll(`[data-tag^="${companySetting}"]`);

                    // loop through all elements with the tag
                    for (const element of elements) {
                        if (element) {
                            element.closest('.opblock-tag-section').style.display = 'none';
                        }
                    }

                }
            }
        }
    }
}
</script>
