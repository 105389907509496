<template>
    <div>
        <form @submit.prevent="createCriteriaSet">
            <div class="card-body">
                <!-- Criteria Set Form -->
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="name" required>Criteria Set Name</label>
                            <input
                                type="text"
                                id="name"
                                class="form-control"
                                v-model="criteriaSet.name"
                                required
                            />
                        </div>
                    </div>
                </div>

                <!-- Criteria Configs -->
                <label for="criteria" required>Criteria</label>

                <div v-for="(config, index) in criteriaConfigs" :key="index">
                    <div class="row mb-3">
                        <!-- Attribute Selection -->
                        <div class="col-md-3">
                            <div class="form-group">
                                <v-select
                                    v-model="config.attribute"
                                    :options="attributes"
                                    label="name"
                                    placeholder="Select an option or type to search"
                                    :clearable="false"
                                    required
                                ></v-select>
                            </div>
                        </div>

                        <!-- Rule Selection -->
                        <div class="col-md-3">
                            <div class="form-group">
                                <select class="form-select" v-model="config.rule" required>
                                    <option value="" disabled>Select a rule</option>
                                    <option
                                        v-for="rule in getAvailableRules(config.attribute)"
                                        :key="rule.value"
                                        :value="rule.value"
                                    >
                                        {{ rule.text }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- Value Input (conditionally rendered) -->
                        <div class="col-md-3" v-if="shouldShowValueInput(config.rule)">
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="config.value" required />
                            </div>
                        </div>

                        <!-- Remove Criteria Button -->
                        <div :class="{'col-md-1': shouldShowValueInput(config.rule), 'col-md-4': !shouldShowValueInput(config.rule)}">
                            <button
                                type="button"
                                class="btn btn-danger btn-block"
                                @click="removeCriteriaConfig(index)"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Add Criteria Button -->
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn btn-sm btn-secondary" @click="addCriteriaConfig">
                            Add Additional Criteria
                        </button>
                    </div>
                </div>
            </div>

            <!-- Form Footer -->
            <div class="card-footer">
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-primary">Save Criteria Set</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
    components: { vSelect },
    data() {
        return {
            criteriaSet: {
                name: ''
            },
            criteriaConfigs: [
                {
                    attribute: null,
                    rule: '',
                    value: ''
                }
            ],
            attributes: [],
            allRules: [
                { value: '==', text: 'Equals' },
                { value: '!=', text: 'Does Not Equal' },
                { value: '>', text: 'Greater Than' },
                { value: '<', text: 'Less Than' },
                { value: '>=', text: 'Greater Than or Equal To' },
                { value: '<=', text: 'Less Than or Equal To' },
                { value: 'IN', text: 'IN' },
                { value: 'NOT IN', text: 'NOT IN' },
                { value: 'isNull', text: 'Is Null' },
                { value: 'isNotNull', text: 'Is Not Null' },
            ]
        };
    },
    mounted() {
        this.fetchAttributes();
    },
    methods: {
        createCriteriaSet() {
            const payload = {
                name: this.criteriaSet.name,
                criteria_configs: this.criteriaConfigs.map((config) => ({
                    attribute: config.attribute.attribute,
                    rule: config.rule,
                    value: this.shouldShowValueInput(config.rule) ? config.value : null
                }))
            };

            axios
                .post('/ps/criteria-set', payload)
                .then((response) => {
                    window.location.href = '/ps/criteria-sets';
                })
                .catch((error) => {
                    console.error('Error creating Criteria Set and Configurations:', error);
                });
        },
        addCriteriaConfig() {
            this.criteriaConfigs.push({
                attribute: null,
                rule: '',
                value: ''
            });
        },
        removeCriteriaConfig(index) {
            this.criteriaConfigs.splice(index, 1);
        },
        fetchAttributes() {
            axios
                .get('/ps/get-attributes-for-user')
                .then((response) => {
                    this.attributes = response.data;
                })
                .catch((error) => {
                    console.error('Error fetching attributes:', error);
                });
        },
        getAvailableRules(attribute) {
            if (!attribute || !attribute.type) {
                return this.allRules.filter(
                    (op) => !['>', '<', '>=', '<='].includes(op.value)
                );
            }

            if (attribute.type === 'int' || attribute.type === 'decimal') {
                return this.allRules;
            }

            return this.allRules.filter(
                (op) => !['>', '<', '>=', '<='].includes(op.value)
            );
        },
        shouldShowValueInput(rule) {
            return rule !== 'isNull' && rule !== 'isNotNull';
        }
    },
    watch: {
        criteriaConfigs: {
            handler(newConfigs, oldConfigs) {
                newConfigs.forEach((config, index) => {
                    const oldConfig = oldConfigs[index] || {};
                    if (config.attribute !== oldConfig.attribute) {
                        const availableRules = this.getAvailableRules(config.attribute);
                        if (!availableRules.find((rule) => rule.value === config.rule)) {
                            config.rule = '';
                        }
                    }
                    if (!this.shouldShowValueInput(config.rule)) {
                        config.value = '';
                    }
                });
            },
            deep: true
        }
    }
};
</script>
