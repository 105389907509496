<template>
    <div class="output-layout-component">
        <label>Priority Sets</label>
        <div class="mb-3">
            <div class="form-check">
                <input
                    type="radio"
                    id="noPriorityType"
                    value="none"
                    v-model="selectedPriorityType"
                    class="form-check-input"
                    @change="handlePriorityTypeChange"
                />
                <label for="noPriorityType" class="form-check-label">Don't prioritize results</label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    id="usePriorityType"
                    value="existing"
                    v-model="selectedPriorityType"
                    class="form-check-input"
                    @change="handlePriorityTypeChange"
                />
                <label for="usePriorityType" class="form-check-label">Use existing priority set</label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    id="createPriorityType"
                    value="new"
                    v-model="selectedPriorityType"
                    class="form-check-input"
                    @change="handlePriorityTypeChange"
                />
                <label for="createPriorityType" class="form-check-label">Create new priority set</label>
            </div>
        </div>

        <div v-if="selectedPriorityType === 'existing'">
            <div class="row">
                <div class="col-sm-9 col-xl-6">
                    <v-select
                        v-model="selectedPrioritySet"
                        :options="prioritySets"
                        label="name"
                        :reduce="layout => layout.slug"
                        @update:modelValue="onPrioritySetSelected"
                        placeholder="Select a Priority Set"
                        :clearable="false"
                        class="mb-3"
                    ></v-select>
                </div>
            </div>
        </div>

        <div
            v-if="selectedPriorityType === 'new' || (selectedPriorityType === 'existing' && selectedPrioritySet)"
            class="mb-2"
        >
            <label
                :for="selectedPriorityType === 'new' ? 'newPrioritySetName' : 'existingPrioritySetName'"
                class="form-label"
                required
            >
                Priority Set Name
            </label>
            <div class="row">
                <div class="col-sm-9 col-xl-6">
                    <input
                        :id="selectedPriorityType === 'new' ? 'newPrioritySetName' : 'existingPrioritySetName'"
                        v-model="prioritySetName"
                        class="form-control mb-2"
                        @input="checkForChanges"
                        placeholder="Priority Set Name"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="selectedPriorityType === 'new' || (selectedPriorityType === 'existing' && selectedPrioritySet)"
            class="mb-3"
        >
            <label required>Attributes</label>
            <div class="mb-3">
                <small class="form-text text-muted">Select attributes and specify their sort order. The order of selection determines the priority.</small>
            </div>

            <div
                v-for="(attribute, index) in sortableAttributes"
                :key="attribute.id"
                class="mb-2"
            >
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex align-items-center gap-2">
                            <div class="flex-grow-1">
                                <v-select
                                    v-model="attribute.selected"
                                    :options="availableAttributes"
                                    :get-option-label="(option) => `${option.name} (${option.attribute})`"
                                    :reduce="attr => attr.attribute"
                                    placeholder="Select an attribute"
                                    :clearable="false"
                                    @input="onAttributeChange(attribute)"
                                >
                                    <template #selected-option="option">
                                        <span :style="{
                                            fontSize: `${Math.max(10, Math.min(14, 14 - ((option.name + option.attribute).length - 32) * 0.45))}px`
                                        }">
                                            {{ option.name }} ({{ option.attribute }})
                                        </span>
                                    </template>
                                </v-select>
                            </div>
                            <div
                                v-if="attribute.selected && getAttributeDescription(attribute.selected)"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                :title="formatDescription(getAttributeName(attribute.selected), getAttributeDescription(attribute.selected))"
                                data-bs-html="true"
                                class="d-flex align-items-center"
                            >
                                <i class="fas fa-info-circle text-primary"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <select
                            v-model="attribute.sortOrder"
                            class="form-select"
                            @change="onSortOrderChange"
                        >
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </div>
                    <div class="col-auto">
                        <button
                            @click="removeAttribute(index)"
                            class="btn btn-danger"
                            :disabled="sortableAttributes.length === 1"
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <button
                    @click="addAttribute"
                    class="btn btn-secondary mt-2"
                    :disabled="sortableAttributes.length >= maxAttributesCount"
                >
                    Add Attribute
                </button>
            </div>

            <div v-if="sortableAttributes.length >= maxAttributesCount" class="alert alert-warning mt-3">
                You have reached the maximum limit of {{ maxAttributesCount }} attributes.
            </div>
        </div>

        <div
            v-if="selectedPriorityType !== 'none'"
            class="mt-3 mb-3"
        >
            <button
                @click="saveAttributeSorting"
                class="btn btn-primary"
                :disabled="!isChanged || !isValid"
            >
                Save Priority Set
            </button>
        </div>

        <div v-if="updateSuccess" class="alert alert-success mt-3">
            Priority set saved successfully.
        </div>
    </div>
</template>

<script>
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import axios from 'axios';
import vSelect from 'vue-select';

export default {
    name: 'AttributeSorterComponent',
    components: {
        vSelect
    },
    props: {
        initialSlug: {
            type: String,
            default: '',
        },
        maxAttributes: {
            type: Number,
            default: 10
        },
        selectedCompany: {
            type: String,
            required: true
        }
    },
    emits: [
        'update:config',
        'config-changed',
        'config-saved',
        'priority-type-changed',
        'priority-set-selected'
    ],
    setup(props, { emit }) {
        const selectedPriorityType = ref('none');
        const selectedPrioritySet = ref(null);
        const prioritySetName = ref('');
        const sortableAttributes = ref([]);
        const prioritySets = ref([]);
        const availableAttributes = ref([]);
        const originalConfig = ref(null);
        const isChanged = ref(false);
        const updateSuccess = ref(false);
        const maxAttributesCount = computed(() => props.maxAttributes);

        const isValid = computed(() => {
            if (selectedPriorityType.value === 'none') return true;
            return (
                prioritySetName.value.trim() !== '' &&
                sortableAttributes.value.length > 0 &&
                sortableAttributes.value.every(attr => attr.selected && attr.sortOrder)
            );
        });

        const onPrioritySetSelected = (value) => {
            console.log("Priority set selected:", value);
            selectedPrioritySet.value = value;
            emit('priority-set-selected', value);  // Emit immediately on selection
            loadPrioritySet();  // Then load the set details
        };

        const loadAttributes = async () => {
            try {
                const response = await axios.get('/ps/get-attributes-for-user');
                availableAttributes.value = response.data;
            } catch (error) {
                console.error('Error loading attributes:', error);
            }
        };

        const loadPrioritySets = async () => {
            try {
                const response = await axios.get('/ps/get-priority-sets-for-user');
                prioritySets.value = response.data;
            } catch (error) {
                console.error('Error loading priority sets:', error);
            }
        };

        const checkForChanges = () => {
            if (!originalConfig.value) return;

            const currentState = {
                name: prioritySetName.value,
                attributes: sortableAttributes.value
            };

            isChanged.value = JSON.stringify(currentState) !== JSON.stringify(originalConfig.value);
            emit('config-changed', isChanged.value);
        };

        const loadPrioritySet = () => {
            if (selectedPrioritySet.value && selectedPriorityType.value === 'existing') {
                const set = prioritySets.value.find(set => set.slug === selectedPrioritySet.value);

                if (set) {
                    prioritySetName.value = set.name;
                    sortableAttributes.value = set.configs.map(attr => ({
                        id: Date.now(),
                        selected: attr.attribute,
                        sortOrder: attr.is_ascending ? 'asc' : 'desc'
                    }));

                    originalConfig.value = {
                        name: prioritySetName.value,
                        attributes: JSON.parse(JSON.stringify(sortableAttributes.value))
                    };
                    isChanged.value = false;
                    emit('config-changed', false);

                    emit('priority-set-selected', selectedPrioritySet.value);
                }
            } else {
                resetNewPrioritySet();
            }
        };

        const resetNewPrioritySet = () => {
            prioritySetName.value = '';
            sortableAttributes.value = [{
                id: Date.now(),
                selected: null,
                sortOrder: 'asc'
            }];
            originalConfig.value = {
                name: '',
                attributes: [{
                    id: Date.now(),
                    selected: null,
                    sortOrder: 'asc'
                }]
            };
            isChanged.value = false;
            emit('config-changed', false);
        };

        const handlePriorityTypeChange = () => {
            emit('priority-type-changed', selectedPriorityType.value);
            if (selectedPriorityType.value === 'new') {
                resetNewPrioritySet();
                emit('priority-set-selected', null);
            } else if (selectedPriorityType.value === 'existing') {
                loadPrioritySet();
            } else {
                selectedPrioritySet.value = null;
                prioritySetName.value = '';
                sortableAttributes.value = [];
                originalConfig.value = null;
                isChanged.value = false;
                emit('config-changed', false);
                emit('priority-set-selected', null);
            }
        };

        const addAttribute = () => {
            if (sortableAttributes.value.length < props.maxAttributes) {
                sortableAttributes.value.push({
                    id: Date.now(),
                    selected: null,
                    sortOrder: 'asc'
                });
                checkForChanges();
            }
        };

        const removeAttribute = (index) => {
            if (sortableAttributes.value.length > 1) {
                sortableAttributes.value.splice(index, 1);
                checkForChanges();
            }
        };

        const getAvailableAttributes = (currentIndex) => {
            return availableAttributes.value.filter(attr =>
                !sortableAttributes.value.some((selected, index) =>
                    index !== currentIndex && selected.selected === attr.attribute
                )
            );
        };

        const formatDescription = (name, description) => {
            if (!description) return `<strong>${name}</strong>`;
            return `<strong>${name}</strong><br>${description}`
                .replace(/\\n/g, '<br>')
                .replace(/• /g, '&bull; ')
                .replace(/\s+/g, ' ')
                .trim();
        };

        const getAttributeDescription = (attributeKey) => {
            const attribute = availableAttributes.value.find(attr => attr.attribute === attributeKey);
            return attribute ? attribute.description : null;
        };

        const getAttributeName = (attributeKey) => {
            const attribute = availableAttributes.value.find(attr => attr.attribute === attributeKey);
            return attribute ? attribute.name : attributeKey;
        };

        const initializeTooltips = () => {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipTriggerList.forEach(tooltipTriggerEl => {
                const tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
                if (tooltip) {
                    tooltip.dispose();
                }
                new bootstrap.Tooltip(tooltipTriggerEl, {
                    html: true
                });
            });
        };

        const onAttributeChange = async (attribute) => {
            checkForChanges();
            await nextTick();
            initializeTooltips();
        };

        const onSortOrderChange = () => {
            checkForChanges();
        };

        const saveAttributeSorting = async () => {
            try {
                if (selectedPriorityType.value === 'none') {
                    emit('config-saved', null);
                    updateSuccess.value = true;
                    setTimeout(() => {
                        updateSuccess.value = false;
                    }, 3000);
                    return;
                }

                const payload = {
                    priority_setting: {
                        name: prioritySetName.value,
                        attributes: sortableAttributes.value.map(attr => ({
                            attribute: attr.selected,
                            is_ascending: attr.sortOrder === 'asc',
                            slug: attr.id
                        }))
                    },
                    company: props.selectedCompany
                };

                let response;
                let savedPrioritySetSlug;

                if (selectedPriorityType.value === 'new') {
                    response = await axios.post('/ps/priority-set', payload);
                    savedPrioritySetSlug = response.data.slug;
                } else {
                    response = await axios.put(`/ps/priority-set/${selectedPrioritySet.value}`, payload);
                    savedPrioritySetSlug = selectedPrioritySet.value;
                }

                await reloadPrioritySets(savedPrioritySetSlug);

                // Ensure we're emitting the correct data structure
                emit('config-saved', {
                    slug: savedPrioritySetSlug,
                    type: 'existing'
                });

                updateSuccess.value = true;
                setTimeout(() => {
                    updateSuccess.value = false;
                }, 3000);

            } catch (error) {
                console.error('Error saving priority settings:', error);
                alert('Error saving priority settings. Please try again.');
            }
        };

        const reloadPrioritySets = async (prioritySetSlugToSelect) => {
            try {
                await loadPrioritySets();
                selectedPriorityType.value = 'existing';

                const setToSelect = prioritySets.value.find(
                    set => set.slug === prioritySetSlugToSelect
                );

                if (setToSelect) {
                    selectedPrioritySet.value = setToSelect.slug;
                    prioritySetName.value = setToSelect.name;
                    await loadPrioritySet();
                }

                isChanged.value = false;
                emit('config-changed', false);
            } catch (error) {
                console.error('Error reloading priority sets:', error);
            }
        };

        watch([prioritySetName, sortableAttributes], checkForChanges, { deep: true });

        // Watch for changes in selectedPrioritySet
        watch(selectedPrioritySet, (newValue) => {
            if (newValue) {
                loadPrioritySet();
            }
        });

        watch(selectedPrioritySet, (newValue) => {
            if (newValue && selectedPriorityType.value === 'existing') {
                emit('priority-set-selected', newValue);
            }
        });

        watch(sortableAttributes, () => {
            nextTick(() => {
                initializeTooltips();
            });
        }, { deep: true });

        onMounted(async () => {
            await Promise.all([
                loadPrioritySets(),
                loadAttributes()
            ]);

            if (props.initialSlug) {
                selectedPriorityType.value = 'existing';
                selectedPrioritySet.value = props.initialSlug;
                await loadPrioritySet();
            } else {
                resetNewPrioritySet();
            }

            originalConfig.value = {
                name: prioritySetName.value,
                attributes: JSON.parse(JSON.stringify(sortableAttributes.value))
            };
            initializeTooltips();
        });

        watch(() => props.initialSlug, async (newSlug) => {
            if (newSlug) {
                selectedPriorityType.value = 'existing';
                selectedPrioritySet.value = newSlug;
                await loadPrioritySet();
            }
        }, { immediate: true });

        return {
            selectedPriorityType,
            selectedPrioritySet,
            prioritySetName,
            sortableAttributes,
            prioritySets,
            availableAttributes,
            isChanged,
            isValid,
            updateSuccess,
            maxAttributesCount,
            loadPrioritySet,
            addAttribute,
            removeAttribute,
            saveAttributeSorting,
            checkForChanges,
            handlePriorityTypeChange,
            getAvailableAttributes,
            formatDescription,
            getAttributeDescription,
            getAttributeName,
            onAttributeChange,
            onSortOrderChange,
            onPrioritySetSelected
        };
    }
};
</script>

<style>
.vs__selected {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.vs__selected span {
    line-height: 1.2;
    white-space: normal;
}

.tooltip-inner {
    text-align: left !important;
    max-width: 500px !important;
    white-space: normal !important;
}
</style>
